
import { mapState, mapActions } from 'vuex'
import ProductSlider from '@/components/product/swiper'
import CategoriesGroup from '@/components/product/swiper/category'
import SearchBar from '@/components/search/SearchBar/'
export default {
  layout: 'index',
  components: {
    ProductSlider,
    SearchBar,
    CategoriesGroup,
  },
  data() {
    return {
      jumbotron: {
        dynamic_height: '100vh',
      },
      tags_group: [
        {
          title: this.$t('search_input.hot_search'),
          tags: [
            { txt: '抹茶' },
            { txt: '巧克力' },
            { txt: '和菓' },
            { txt: '餅乾' },
            { txt: '蛋糕' },
            { txt: '大福' },
          ],
        },
      ],
      lazyfechAnchors: [],
    }
  },
  computed: {
    ...mapState('trend_group/product/list', ['trend_groups']),
    ...mapState('category/list', ['categories']),

    hot_groups: {
      get() {
        return this.trend_groups.length ? this.trend_groups : 3
      },
      set() {
        this.trend_groups.length ? this.trend_groups : 3
      },
    },
  },

  beforeMount() {
    window.addEventListener('scroll', this.handleFetch)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleFetch)
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      this.initAnchors()
      this.handleFetch()
      this.initWishlist()
    })
    this.$i18n.setLocale(this.$route.query.locale)
    window.addEventListener('resize', this.onResize)
  },
  methods: {
    ...mapActions({
      getTrendGroup: 'trend_group/product/list/getTrendGroups',
      getCategories: 'category/list/getCategories',
      getWishlist: 'product/wishlist/getWishlist',
      resetWishlist: 'product/wishlist/resetWishlist',
    }),

    initAnchors() {
      const lazyfechAnchors = document.querySelectorAll('*[data-lazyfetch]')

      lazyfechAnchors.forEach((item) => {
        this.lazyfechAnchors.push({
          elem: item,
          offsetTop: item.offsetTop - (window.innerHeight / 3) * 2,
          fetch: item.dataset.lazyfetch,
          load: false,
        })
      })
    },

    initWishlist() {
      if (this.$auth.loggedIn) {
        this.getWishlist()
      } else {
        this.resetWishlist()
      }
    },

    handleFetch() {
      let y = window.pageYOffset

      const fetched = this.lazyfechAnchors.map((item) => item.load)
      const toCheck = fetched.includes(false)

      if (toCheck) {
        for (let i = 0; i < this.lazyfechAnchors.length; i++) {
          if (y > this.lazyfechAnchors[i].offsetTop) {
            if (this.lazyfechAnchors[i].load == false) {
              //fetch
              this.handleFetchEvents(this.lazyfechAnchors[i].fetch)

              this.lazyfechAnchors[i].load = true
              this.lazyfechAnchors[i].elem.dataset.load = true
            }
          }
        }
      }
    },

    handleFetchEvents(event) {
      switch (event) {
        case 'category':
          this.getCategories()
          break
        case 'trend-group':
          this.getTrendGroup()
          break
      }
    },

    onResize() {
      this.jumbotron.dynamic_height = `${window.innerHeight}px`
    },
  },
}
